import { Container, Row, Col } from "react-bootstrap";
import './Footer.css';
import resume from '../../Assets/Images/Jacob-Dekok-Resume.pdf';

export default function FooterComponent() {
    return (
        <div>
            <hr className="footerTop"/>
            <Container className="footerSpacing">
                <Row>
                <Col className="footerCol">
                        <a href={resume} target='_blank' className="dmSerif resumeText">
                            <p>Resume</p>
                        </a>
                    </Col>
                    <Col className="footerCol">
                        <p className="dmSans">(916) 579-1851 • jacob.dekok01@gmail.com</p>
                    </Col>
                    <Col className="footerCol">
                    <a href='https://www.linkedin.com/in/jacob-dekok/'>
                        <i className="fa-brands fa-linkedin linkIcon fa-2xl"></i>
                    </a>
                    <a href='https://github.com/KingHakoob'>
                        <i className="fa-brands fa-github gitIcon fa-2xl"></i>
                    </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}