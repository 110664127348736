import './Project.css';
import { Col, Container, Row } from "react-bootstrap";
import BusyBee from '../../Assets/Images/BusyBee.png';
import Bland from '../../Assets/Images/bland-login.png';
import Captain from '../../Assets/Images/Captain.png'
import iHub from '../../Assets/Images/iHub.png'
import ProjectCard from '../ProjectCardComponent/ProjectCard';
import { SiAngular, SiCsharp, SiMicrosoftazure, SiNextdotjs, SiReact, SiTypescript } from 'react-icons/si';

export default function Projects() {
  const projectInfo = [
    {
      img: iHub,
      name: "iHub",
      languages: "React | TypeScript | C#",
      description: "Created an all in one styled dynamic input component used for every input on the site.",
      link: "https://www.ihubsj.org/",
      languageIcon: [
        { icon: <SiReact size={50} color="#61dbfb" />, text: "React" },
        { icon: <SiTypescript size={50} color="#007acc" />, text: "TypeScript" },
        { icon: <SiCsharp size={50} color="#6A1577" />, text: "C#" },
      ],
      btnText: "Visit Site"
    },
    {
      img: Captain,
      name: "Captain",
      languages: "Angular | C#",
      description: "Used Razor Pages to create and format emails, with attached resources, after attending meetings and/or events.",
      link: "https://captaindata.org/",
      languageIcon: [
        { icon: <SiAngular size={50} color="#DD0031" />, text: "Angular" },
        { icon: <SiCsharp size={50} color="#6A1577" />, text: "C#" },
      ],
      btnText: "Visit Site"
    },
    {
      img: BusyBee,
      name: "Busy Bee",
      languages: "React | TypeScript | C# | Azure Service",
      description: "Allows parents to assign tasks to their children, and create rewards as an incentive to complete them.",
      link: "https://github.com/Ulissesortega/busybeedeskptop",
      languageIcon: [
        { icon: <SiReact size={50} color="#61dbfb" />, text: "React" },
        { icon: <SiTypescript size={50} color="#007acc" />, text: "TypeScript" },
        { icon: <SiCsharp size={50} color="#6A1577" />, text: "C#" },
        { icon: <SiMicrosoftazure size={50} color="#008AD7"/> , text: "Azure Service" }
      ],
      btnText: "Visit Repo"
    },
    // {
    //   img: SushiSurfers,
    //   name: "Sushi Surfers",
    //   languages: "Currently Designing Prototype",
    //   description: "In Development. I am building this project to challenge my design and CSS skills.",
    //   link: "",
    //   languageIcon: [],
    //   btnText: "Under Development"
    // },
    {
      img: Bland,
      name: "Bland",
      languages: "NextJS | TypeScript | C# | Azure Service",
      description: "In Development. I started this social media project to build and understand features that I haven't worked with yet.",
      link: "",
      languageIcon: [
        { icon: <SiNextdotjs size={50} />, text: "NextJS" },
        { icon: <SiTypescript size={50} color="#007acc" />, text: "TypeScript" },
        { icon: <SiCsharp size={50} color="#6A1577" />, text: "C#" },
        { icon: <SiMicrosoftazure size={50} color="#008AD7"/> , text: "Azure Service" }
      ],
      btnText: "Under Development"
    },
  ];

  return (
    <Container className='projectContainer'>
      <Row>
        <Col>
          <h1 className='projects dmSerif'>Projects</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProjectCard project={projectInfo} />
        </Col>
      </Row>
    </Container>
  );
}