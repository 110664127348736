import './Welcome.css';
import { Container, Row, Col } from 'react-bootstrap';
import picOfMe from '../../Assets/Images/Headshots_Jacob_Dekok_Portfolio.jpg';
import resume from '../../Assets/Images/Jacob-Dekok-Resume.pdf';

export default function Welcome() {
    return (
        <Container className='welcomeContainer'>
            <Row className='welcomeRow'>
                <Col>
                    <h1 className='welcome dmSerif'>Welcome!</h1>
                    <h2 className='bioTxt dmSans pb-4'>
                        My name is Jacob Dekok <br />
                        <span className='bioIndent'>Fullstack Engineer at CodeStack SJCOE</span> <br />
                        <span className='bioIndent'>Teachers Assistant at CodeStack Academy</span>
                    </h2>
                    <h4 className='bioTxt dmSans'>(209) 353-4008 • jacob.dekok01@gmail.com</h4>
                    <h4 className='bioTxt dmSans'>Based in California</h4>
                    <div className='d-flex pt-4 pb-4'>
                        <a href='https://www.linkedin.com/in/jacob-dekok/' target='_blank'>
                            <i className="fa-brands fa-linkedin linkIcon fa-2xl"></i>
                        </a>
                        <a href='https://github.com/KingHakoob' target='_blank'>
                            <i className="fa-brands fa-github gitIcon fa-2xl"></i>
                        </a>
                        <a href={resume} target='_blank' className='resumeTxt dmSerif'>
                            <h4>Resume</h4>
                        </a>
                    </div>
                    <div className='bellowDiv'>
                        <h2 className='bioTxt dmSans'>View Skills and Projects Below</h2>
                        <a href="#skills">
                            <i className="fa-solid fa-angles-down fa-beat fa-2xl arrowIcon"></i>
                        </a>
                    </div>
                </Col>
                <Col>
                    <Row>
                        <Col className='picture-col'>
                    <       img className='picOfMe' src={picOfMe} alt="Jacob Dekok" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}