import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Components/HomePage/homepage';
import { useState } from 'react';
import { DarkModeContext } from './Context/context';

function App() {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const setDarkMode = (isDarkMode: boolean) => {
    setIsDarkMode(isDarkMode)
  }

  return (
    <DarkModeContext.Provider value={{isDarkMode, setDarkMode}}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </DarkModeContext.Provider>
  );
}

export default App;
