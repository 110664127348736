import './homepage.css';
import Projects from '../ProjectComponent/Project';
import Welcome from '../WelcomeComponent/Welcome';
import NavbarComponent from '../NavbarComponent/NavbarComponent';
import FooterComponent from '../FooterComponent/FooterComponent';
import Skills from '../SkillsComponent/Skills';
import { DarkModeContext } from '../../Context/context';
import { useContext } from 'react';

export default function HomePage() {
    const { isDarkMode } = useContext(DarkModeContext);

    return (
        <div className={isDarkMode ? "dark" : "light"}>
            <NavbarComponent />
            <div id='welcome'>
                <Welcome />
            </div>
            <div id='skills'>
                <Skills />
            </div>
            <div id='projects'>
                <Projects />
            </div>
            <FooterComponent />
        </div>
    );
}