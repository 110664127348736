import { Navbar, Nav, Container, Form } from "react-bootstrap";
import './navbar.css';
import { useContext } from "react";
import { DarkModeContext } from "../../Context/context";

export default function NavbarComponent() {
    
    const { isDarkMode, setDarkMode } = useContext(DarkModeContext);
    // let [isDarkMode, setIsDarkMode] = useState(false);

    const changeMode = () => {
        setDarkMode(!isDarkMode);
    }

    return (
        <div>
            {/* Want Navbar to be fixed top instead of sticky top */}
            <Navbar className="bgColor" collapseOnSelect expand="lg" fixed="top"> 
                <Container className="navContainer">
                    <Navbar.Brand className="dmSerif">Jacob Dekok</Navbar.Brand>
                    {/* <Form className="toggle d-lg-none">
                        <Form.Check type="switch" id="custom-switch" defaultChecked={isDarkMode} onClick={changeMode} />
                    </Form> */}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link className="dmSans navText" href="#welcome">About Me</Nav.Link>
                            <Nav.Link className="dmSans navText" href="#skills">Skills</Nav.Link>
                            <Nav.Link className="dmSans navText" href="#projects">Projects</Nav.Link>
                            {/* <Form className="toggle pt-2 ms-5 d-none d-lg-block">
                                <Form.Check type="switch" id="custom-switch" defaultChecked={isDarkMode} onClick={changeMode} />
                            </Form> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}