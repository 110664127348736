import { createContext } from "react";

interface IDarkModeContext {
    isDarkMode: boolean,
    setDarkMode: (isDarkMode: boolean) => void
}

export const DarkModeContext = createContext<IDarkModeContext>({
    isDarkMode: false,
    setDarkMode: () => {}
})