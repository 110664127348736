import { Container, Row, Col } from "react-bootstrap";
import './Skills.css';
import { SiCsharp, SiHtml5, SiCss3, SiJavascript, SiTypescript, SiReact, SiAngular, SiBootstrap, SiTailwindcss, SiPostman, SiMicrosoftazure, SiFigma, SiAzuredevops, SiNextdotjs } from 'react-icons/si';
import { TbBrandVscode } from 'react-icons/tb';

export default function Skills() {
    return (
        <Container className="skillsContainer">
            <Row>
                <Col>
                    <h1 className="skillsHeader dmSerif">Skills</h1>
                </Col>
            </Row>
            <Row>
                <Col className='skillsCol dmSans'>
                    <h2 className="skillsSectionTitle">Languages</h2>
                    <div>
                        <div className="skillDiv">
                            <SiCsharp size={40} color="#6A1577" /> 
                            <p className="skillsText">C#</p>
                        </div>
                        <div className="skillDiv">
                            <SiHtml5 size={40} color="#e34c26" /> 
                            <p className="skillsText">HTML</p>
                        </div>
                        <div className="skillDiv">
                            <SiCss3 size={40} color="#264de4" /> 
                            <p className="skillsText">CSS</p>
                        </div>
                        <div className="skillDiv">
                            <SiJavascript size={40} color="#F0DB4F" /> 
                            <p className="skillsText">JavaScript</p>
                        </div>
                        <div className="skillDiv">
                            <SiTypescript size={40} color="#007acc" /> 
                            <p className="skillsText">TypeScript</p>
                        </div>
                    </div>
                </Col>
                <Col className='skillsCol dmSans'>
                    <h2 className="skillsSectionTitle">Libraries and Frameworks</h2>
                    <div>
                        <div className="skillDiv">
                            <SiNextdotjs size={40} /> 
                            <p className="skillsText">Next.JS</p>
                        </div>
                        <div className="skillDiv">
                            <SiReact size={40} color="#61dbfb" /> 
                            <p className="skillsText">React</p>
                        </div>
                        <div className="skillDiv">
                            <SiAngular size={40} color="#DD0031" /> 
                            <p className="skillsText">Angular</p>
                        </div>
                        <div className="skillDiv">
                            <SiBootstrap size={40} color="#563d7c" /> 
                            <p className="skillsText">Bootstrap</p>
                        </div>
                        <div className="skillDiv">
                            <SiTailwindcss size={40} color="#38BDF8" /> 
                            <p className="skillsText">Tailwind Css</p>
                        </div>
                    </div>
                </Col>
                <Col className='skillsCol dmSans'>
                    <h2 className="skillsSectionTitle">Tools</h2>
                    <div>
                        <div className="skillDiv">
                            <TbBrandVscode size={40} color="#0078d7" /> 
                            <p className="skillsText">Visual Studio Code</p>
                        </div>
                        <div className="skillDiv">
                            <SiAzuredevops size={40} color="#008AD7" /> 
                            <p className="skillsText">Azure DevOps</p>
                        </div>
                        <div className="skillDiv">
                            <SiMicrosoftazure size={40} color="#008AD7" /> 
                            <p className="skillsText">Azure Service</p>
                        </div>
                        <div className="skillDiv">
                            <SiPostman size={40} color="#FE6C37" /> 
                            <p className="skillsText">Postman</p>
                        </div>
                        <div className="skillDiv">
                            <SiFigma size={40} className="figmaIcon" /> 
                            <p className="skillsText">Figma</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}