import { Container, Row, Col, Button } from "react-bootstrap";
import './ProjectCard.css'

export default function ProjectCard ({ project }: { project: any }) {
    let isOdd = project.length%2 === 1;
    return (
        <Container>
            <Row>
                {project.map((projectItem: any, index: number) => {
                    return (
                        <Col lg={isOdd && project.length === index+1 ? 12 : 6} key={index} className={isOdd && project.length === index+1 ? 'odd-one-out' : 'normal'} >
                                <div className="project-card">
                                    <div className="project-bio">
                                        <header>
                                            <img src={projectItem.img} alt="Project" />
                                        </header>
                                        <h1 className="project-name dmSerif">{projectItem.name}</h1>
                                        <h2 className="dmSerif">{projectItem.languages}</h2>
                                        <p className="dmSans">{projectItem.description}</p>
                                        <Row>
                                            {projectItem.languageIcon.map((icon: any, idx: number) => {
                                                return (
                                                    <Col key={idx} className='icon-col dmSans'>
                                                        {icon.icon}
                                                        {icon.text}
                                                    </Col>
                                                )
                                            })
                                            }
                                        </Row>
                                        <div className='project-btn-div'>
                                            <Button className="project-btn dmSans" href={projectItem.link} target="_blank" rel="noopener noreferrer">
                                                {projectItem.btnText}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
}